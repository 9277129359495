import React, { useState, useEffect } from "react";

export const siteContext = React.createContext();

const SiteProvider = (props) => {
    const [isHydrated, setIsHydrated] = useState(false);

    useEffect(() => {
        setIsHydrated(true);
    }, []);

    return (
        <siteContext.Provider
            value={{
                isHydrated,
            }}
        >
            {props.children}
        </siteContext.Provider>
    );
};

// whatever uses this will be wrapped with the Provider
export default ({ element }) => <SiteProvider>{element}</SiteProvider>;
